<div class="modal-container">
  <p *ngIf="!data" class="modal-container--text">{{text}}</p>
  <div *ngIf="!cancelOrder && !data" class="modal-container--btn-container">
    <button *ngIf="!redirect" type="button" class="btn confirm bgColor" (click)="closeModal()">{{'btnContinue' | i18next}}</button>
    <button *ngIf="redirect && !list" type="button" class="btn confirm bgColor" (click)="closeModal()" [routerLink]="['/login']">{{'btnContinue' | i18next}}</button>
    <button *ngIf="redirect && list" type="button" class="btn confirm bgColor" (click)="closeModal()" [routerLink]="['/list-request']">{{'btnContinue' | i18next}}</button>
  </div>
  <div *ngIf="cancelOrder && !data" class="modal-container--btn-container">
    <button class="btn back" type="button" (click)="closeModal()">{{'btnNo' | i18next}}</button>
    <button class="btn confirm bgColor" type="button" (click)="confirmCancel()">{{'btnYes' | i18next}}</button>
  </div>
  <div class="esContainer" *ngIf="data">
    <h4 *ngIf="data && !data.feedback" class="esContainer--title">{{'esGateSelectionTitle' | i18next}} <span class="esContainer--title__bold">{{data.type === 'entry'? entry : exit }}</span></h4>
    <h4 *ngIf="data.feedback && data.feedbackMessage">{{data.feedbackMessage}}</h4>
    <form  *ngIf="data && !data.feedback" [formGroup]="form" class="esContainer--form">
      <select name="gate" id="gate" formControlName="center" required class="esContainer--select">
        <option value="empty">{{'esGateSelect' | i18next}}</option>
        <option *ngFor="let center of centers" value={{center.name}}>{{center.name}}</option>
      </select>
    </form>
    <div class="esContainer--btn-container" *ngIf="!data.feedback">
      <button type="button" class="btn cancel" (click)="closeModal()">{{'btnCancel' | i18next}}</button>
      <button type="button"
              class="btn submit"
              (click)="submit()"
              [disabled]="form.invalid || form.value.center === 'empty'">{{'btnContinue' | i18next}}
      </button>
    </div>
    <div class="esContainer--btn-container" *ngIf="data.feedback">
      <button type="button" class="btn submit" (click)="closeModal()">{{'btnContinue' | i18next}}</button>
    </div>
  </div>
</div>