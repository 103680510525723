import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthorizationResponse } from 'src/app/shared/model/authorization.model';
import { environment } from 'src/environments/environment';

export interface ProfileKeys {
  at: string;
  mk: string;
  omk: string;
  tmk: string;
}
@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  urlEsme = `${environment.API_CONTEXT}/es-service`;

  constructor(
    private http: HttpClient
  ) {}

  ERROR_MESSAGES = {
    wrongProfiles: 'Campaña Invalida, debe ingresar al menos un perfil valido',
    wrongDates:
      'Campaña Invalida, la fecha de inicio debe ser menor a la fecha de finalización'
  };

  getAuthorizationById(uuid: string): Observable<AuthorizationResponse> {
    return this.http.get<AuthorizationResponse>(`${this.urlEsme}/es/${uuid}`);
  }

  getCenters() {
    return this.http.get(`${this.urlEsme}/hubs`);
  }

  RegisterEntryExit(uuid: string, movement: string, centerId: string): Observable<any> {
    return this.http.post(
     `${this.urlEsme}/es/${uuid}/register`,
     {movement, centerId}
    );
  }
}
