<fieldset class="petitioner">
  <legend class="petitioner--legend">{{'esTitle' | i18next}}</legend>
  <section class="userInfo" *ngIf="authorization">
    <div class="column">
      <p class="sectionTitle">{{'esInfoAuth' | i18next}}<p>
      <div class="column--item">
        <p class="title">{{'originCenter' | i18next}}</p>
        <p class="ml">{{ authorization.originCenterId }}</p>
      </div>
      <div class="column--item">
        <p class="title">{{'destinyCenter' | i18next}}</p>
        <p class="ml">{{ authorization.destinationCenterId }}</p>
      </div>
      <div class="column--item">
        <p class="title">{{'reason' | i18next}}</p>
        <p class="ml">{{ authorization.reason }}</p>
      </div><div class="column--item">
        <p class="title">{{'comments' | i18next}}</p>
        <p class="ml">{{ authorization.reasonObservations }}</p>
      </div>
      <div class="column--item">
        <p class="title">{{'checkoutDate' | i18next}}</p>
        <p class="ml">{{ authorization.checkOutDate | date:'shortDate'}}</p>
      </div>
      <div class="column--item">
        <p class="title">{{'checkinDate' | i18next}}</p>
        <p class="ml">{{ authorization.checkInDate | date:'shortDate'}}</p>
      </div>
      <div class="column--item">
        <p class="title">{{'hourOut' | i18next}}</p>
        <p class="ml">{{ authorization.checkOutDate | date:'shortTime' }}</p>
      </div>
      <div class="column--item">
        <p class="title">{{'hourIn' | i18next}}</p>
        <p class="ml">{{ authorization.checkInDate | date:'shortTime' }}</p>
      </div>
    </div>
    <div class="column column2">
      <p class="sectionTitle">{{'esInfoAuthorizer' | i18next}}</p>
      <div class="column--item">
        <p class="title">{{'authorizer' | i18next}}</p>
        <p >{{ authorization.authorizer.fullName }} - {{authorization.authorizer.id}}</p>
      </div>
      <p class="sectionTitle">{{'esInfoDriver' | i18next}}</p>
      <div class="column--item">
        <p class="title">{{'driver' | i18next}}</p>
        <p >{{ authorization.driver.fullName }} - {{authorization.driver.externalDriverId || authorization.driver.internalDriverId}}</p>
      </div>
      <p class="sectionTitle">{{'esInfoVehicle' | i18next}}</p>
      <div class="column--item">
        <p class="title">{{'vins' | i18next}}</p>
        <p>{{ authorization.stockVehicle.vins }}</p>
      </div>
      <div class="column--item">
        <p class="title">{{'redPlate' | i18next}}</p>
        <p>{{ authorization.stockVehicle.redPlate}}</p>
      </div>
      <div class="column--item">
        <p class="title">{{'truckPlateNumber' | i18next}}</p>
        <p>{{ authorization.stockVehicle.truckPlateNumber}}</p>
      </div>
      <div class="column--item">
        <p class="title">{{'comments' | i18next}}</p>
        <p>{{ authorization.observations }}</p>
      </div>
    </div>
  </section>
  <div class="btn-container">
    <button type="submit" class="btn" (click)="updateAuthorization('entry')">{{'entryBtn' | i18next}}</button>
    <button type="submit" class="btn" (click)="updateAuthorization('exit')">{{'exitBtn' | i18next}}</button>
  </div>
</fieldset>
