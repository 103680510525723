import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import i18next from 'i18next';
import { AuthorizationService } from 'src/app/core/services/authorization.service';
import { FeedbackModalComponent } from 'src/app/shared/components/feedback-modal/feedback-modal.component';
import { AuthorizationResponse, UpdateAuthorizationObj } from 'src/app/shared/model/authorization.model';

@Component({
  selector: 'app-es',
  templateUrl: './es.component.html',
  styleUrls: ['./es.component.scss']
})
export class EsComponent implements OnInit {
  uuid = '';
  btnText = '';
  movement!: string;
  authorization!: AuthorizationResponse;
  modalRef: any;
  centers: any;
  errorText = '';
  constructor( private route: ActivatedRoute,
    private authorizationService: AuthorizationService,
    private modalConfig: NgbModal
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.uuid = params['uuid'];
      this.getAuthorization(this.uuid);
      this.getCenters();
      }
    );
  }

  getAuthorization(uuid: string) {
    this.authorizationService.getAuthorizationById(uuid).subscribe({
      next: (resp) => {
        this.authorization = resp;
        this.authorization.checkInDate = new Date(this.authorization.checkInDate+'Z');
        this.authorization.checkOutDate = new Date(this.authorization.checkOutDate+'Z');
      }
    });
  }

  getCenters() {
    this.authorizationService.getCenters().subscribe({
      next: (resp) => {
        this.centers = resp;
      }
    });
  }

  updateAuthorization(data: string) {
    this.modalRef = this.modalConfig.open(FeedbackModalComponent, { windowClass: 'password-modal', centered: true, backdrop : 'static'});
    this.modalRef.componentInstance.data = {type: data, centers: this.centers};
    this.modalRef.componentInstance.updateEntryEmitter.subscribe((resp: UpdateAuthorizationObj) => {
      resp.type === 'entry' ? this.movement = 'ENTRY' : this.movement = 'EXIT';

      this.authorizationService.RegisterEntryExit(this.uuid, this.movement, !!resp.center ? resp.center : '').subscribe({
        next: () => {
          const modalRef = this.modalConfig.open(FeedbackModalComponent,
            { windowClass: 'password-modal', centered: true, backdrop : 'static'});
          modalRef.componentInstance.data = {feedbackMessage: i18next.t('esFeedbackOk'), feedback: true};
        },
        error: () => {
          const modalRef = this.modalConfig.open(FeedbackModalComponent,
            { windowClass: 'password-modal', centered: true, backdrop : 'static'});
          modalRef.componentInstance.data = {feedbackMesssage:i18next.t('esFeedbackKo'), feedback: true};
        }
      });
    });
  }

}
