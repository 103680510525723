import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Centers, UpdateAuthorizationObj } from '../../model/authorization.model';
import i18next from 'i18next';

@Component({
  selector: 'app-feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.scss']
})
export class FeedbackModalComponent implements OnInit{
  @Input() text! : string;
  @Input() redirect? : boolean;
  @Input() list? = false;
  @Input() cancelOrder = false;
  @Input() data?: any;
 
  @Output() confirmCancelOrderEmitter = new EventEmitter<boolean>();
  @Output() updateEntryEmitter = new EventEmitter<UpdateAuthorizationObj>();
  
  centers!: Centers[];
  entry = i18next.t('entryText');
  exit = i18next.t('exitText');

  constructor( private activeModal : NgbActiveModal, 
               private fb: FormBuilder) { }

  ngOnInit(): void {
      this.centers = this.data?.centers;
  }
  
  form: FormGroup = this.fb.group({
    center: ['empty', [Validators.required]]
  });

  closeModal() {
    this.activeModal.close();
  }

  submit() {
    if (this.form.value.center !== 'empty') {
      this.activeModal.close();
      const updateObj: UpdateAuthorizationObj = {
        type: this.data.type,
        center: this.centers.find(s => s.name ===this.form.value.center)?.id
      };
      this.updateEntryEmitter.emit(updateObj);
    }

  }

  confirmCancel() {
    this.activeModal.close();
    this.confirmCancelOrderEmitter.emit(true);
  }
}
